import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import './BookPage.css';
import {Page} from "../../components/Page";
import {SentenceDisplay} from "../../components/SentenceDisplay";
import Skeleton from '@mui/joy/Skeleton';
import Grid from '@mui/joy/Grid';
import Typography from '@mui/joy/Typography';
import {ReadingDifficultyBadge} from "../../components/ReadingDifficultyBadge";
import {APP_NAME, imageURL, server} from "../../globals";
import {UserContext} from "../../contexts";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {TextSegmentDisplay} from "../../components/TextSegmentDisplay";
import {TopicBreadcrumbs} from "../../components/TopicBreadcrumbs";
import Stack from "@mui/joy/Stack";
import {Select, Option, Button} from "@mui/joy";
import LinearProgress from "@mui/joy/LinearProgress";
import Card from "@mui/joy/Card";
import {SourceDisplay} from "../../components/Sources/SourceDisplay";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import {addToRead, addToReading, removeFromRead} from "../../settings";

export const BookPage = () => {
    const params = useParams();

    const [text, setText] = useState<any>(null);
    const [segmentNumber, setSegmentNumber] = useState<number>(0);
    const [segment, setSegment] = useState<any>(null);

    const user = useContext(UserContext);

    const languageCode = params.languageCode
    const topicPath = params["*"] as string
    const textPath: string = `${languageCode}/${topicPath}`;

    const nextChapter = () => {
        setSegmentNumber(Math.min(segmentNumber + 1, text.segments.length - 1))
    }

    const previousChapter = () => {
        setSegmentNumber(Math.max(segmentNumber - 1, 0))
    }

    // Set Page title
    useEffect(() => {
        if (text && segment) {
            document.title = `${text.title} | ${segment.name}`
        } else {
            document.title = APP_NAME
        }
    }, [text, segment])

    // Load Text File
    useEffect(() => {
        const url = `${server}/texts/${textPath}/text.json`
        fetch(url)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setText(res)
            })
    }, [textPath]);

    // Load Segment File
    useEffect(() => {
        if (!text) {
            return;
        }
        const url = `${server}/texts/${textPath}/${text.segments[segmentNumber].id}.json`

        fetch(url)
            .then(res => res.json())
            .then(res => {
                setSegment(res)
            })
    }, [text, segmentNumber])

    // Automatically set loaded segments as "Currently reading"
    useEffect(() => {
        if (!segment || !user) {
            return
        }

        addToReading(user, segment)
    }, [user, segment])

    if (!text) {
        return <Page><VerticalPlaceholder height="5em"/><LinearProgress/></Page>
    }

    return <Page>
        <Card variant="soft">
            <div onContextMenu={event => {
                event.preventDefault();
                return false;
            }}>
                <Grid container spacing={2} sx={{flexGrow: 1}}>
                    <Grid xs={12} md={6}>
                        <Skeleton loading={!text} width="100%" height="512">
                            <img style={{width: "100%"}}
                                 alt=""
                                 src={imageURL(text.segments[segmentNumber].active_image)}
                            />
                        </Skeleton>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div>
                            <TopicBreadcrumbs
                                topic={topicPath.split("/").slice(0, topicPath.split("/").length - 1).join("/")}/>
                        </div>
                        <VerticalPlaceholder height=".5em"/>
                        <Skeleton loading={!text}>
                            <Typography level="h3">
                                {text.title_translation ?
                                    <SentenceDisplay index={-1} sentence={text.title_translation[0]}/> : text.title}
                            </Typography>
                            <Typography level="body-sm">{text.author}</Typography>
                            <p><ReadingDifficultyBadge score={text.difficulty_score}/></p>
                        </Skeleton>
                    </Grid>

                    <Grid xs={12}>
                        <Typography level="title-md" sx={{display: "inline-block"}}>
                            <Select
                                slotProps={{listbox: {sx: {backgroundColor: theme => theme.colorSchemes.light.palette.primary["50"]}}}}
                                sx={{padding: 0, color: "inherit", backgroundColor: theme => theme.colorSchemes.light.palette.neutral.softBg}}
                                placeholder={text.segments[segmentNumber].name}
                                size="lg"
                                variant="plain">
                                {
                                    text.segments.map(
                                        (segment: any, index: number) => <Option
                                            onClick={() => setSegmentNumber(index)}
                                            value={index}
                                            key={index}>{segment.name}</Option>
                                    )
                                }
                            </Select>
                        </Typography>

                        <Stack spacing={2} direction="row">
                            <Typography
                                level="body-sm">Chapter {segmentNumber + 1} of {text ? text.segments.length : '?'}</Typography>
                            {segmentNumber > 0 ? <Typography onClick={previousChapter} style={{cursor: "pointer"}}
                                                             level="body-sm">Previous</Typography> : ''}
                            {text && segmentNumber < text.segments.length - 1 ?
                                <Typography onClick={nextChapter} style={{cursor: "pointer"}}
                                            level="body-sm">Next</Typography> : ''}
                        </Stack>

                        <TextSegmentDisplay segment={segment}/>

                        <Grid xs={12}>
                            {
                                user && segment && user.value.settings.has_read.includes(segment.id)
                                    ? <Button color="neutral" startDecorator={<BeenhereIcon/>} onClick={() => removeFromRead(user, segment)
                                        .then(() => user.update({...user.value}))}>
                                        You marked this text as Done
                                    </Button>
                                    : <Button color="success" startDecorator={<BeenhereIcon/>} onClick={() => addToRead(user, segment)
                                        .then(() => user.update({...user.value}))}>
                                        Mark text as Done
                                    </Button>
                            }
                        </Grid>

                        <SourceDisplay text={text}/>
                    </Grid>
                </Grid>
            </div>
        </Card>
    </Page>
}