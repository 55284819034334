import Chip from "@mui/joy/Chip";
import Tooltip from "@mui/joy/Tooltip"

export const ReadingDifficultyBadge = (props: {score: number}) => {
    const chipProperties = (): {color: "primary" | "neutral" | "danger" | "success" | "warning", label: string} => {
        if (props.score < 10) {
            return {color: "danger", label: "Extremely Difficult"};
        }

        if (props.score < 30) {
            return {color: "danger", label: "Very Difficult"};
        }

        if (props.score < 50) {
            return {color: "danger", label: "Difficult"};
        }

        if (props.score < 70) {
            return {color: "warning", label: "Intermediate"};
        }

        if (props.score < 80) {
            return {color: "success", label: "Fairly Simple"};
        }

        return {color: "success", label: "Simple"}
    }

    return <Tooltip sx={{zIndex: 9999}} title={`Flesch Reading Ease Score: ${Math.round(props.score * 100) / 100}`} placement="right-end" arrow>
        <Chip
            className={"card-chip chip-" + chipProperties().color}
            color={chipProperties().color}
            size="lg"
        >
            {chipProperties().label}
        </Chip>
    </Tooltip>
}