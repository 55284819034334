import React, {useEffect, useState} from 'react';
import './App.css';
import '@fontsource/inter';
import Sheet from '@mui/joy/Sheet';
import {CssVarsProvider, extendTheme} from '@mui/joy/styles';
import {Routing} from "./routing/Routing";
import {UserContext, SelectionContext} from "./contexts"
import "/node_modules/flag-icons/css/flag-icons.min.css";
import {firebase} from "./firebaseConfig";
import {langstoriesTheme} from "./theme";
import {defaultSettings, getUserSettings, persistUserSettings} from "./settings";

const theme = extendTheme(langstoriesTheme);

console.log(theme)

function App() {
    const [user, setUser] = useState({settings: defaultSettings()});
    const [selection, setSelection] = useState({sentence: null, lemma: null, highlight: null, showHighlight: true});

    useEffect(() => {
        console.log("NODE_ENV:", process.env.NODE_ENV)
        firebase.auth().onAuthStateChanged((newUserObj: any) => {
            if (newUserObj) {
                const newUser = {
                    uid: newUserObj.uid,
                    mail: newUserObj.mail,
                    displayName: newUserObj.displayName,
                    photoURL: newUserObj.photoURL
                }
                getUserSettings(newUser)
                    .then((newUserWithSettings: any) => {
                        setUser(newUserWithSettings)
                    })
            } else {
                getUserSettings(null)
                    .then((newUserWithSettings: any) => {
                        setUser(newUserWithSettings)
                    })
            }
        })
    }, [])

    return (
        <div className="App">
            <UserContext.Provider value={{value: user, update: setUser, updateSettings: (settings: any) => new Promise((resolve, reject) => {
                    console.log(user, settings)
                    user.settings = settings
                    // Update using a shallow copy
                    setUser({...user})
                    persistUserSettings(user)
                        .then(resolve)
                        .catch(reject)
                })}}>
                <SelectionContext.Provider value={{value: selection, setValue: setSelection}}>
                    <CssVarsProvider
                        defaultMode="light"
                        theme={theme}
                        colorSchemeSelector="#langstories"
                        // the local storage key to use
                        modeStorageKey="langstories"
                        // set as root provider
                        disableNestedContext>
                        <Sheet id="langstories" color="neutral" sx={{p: 4}} style={{minHeight: "100vh"}}>
                            <Routing/>
                        </Sheet>
                    </CssVarsProvider>
                </SelectionContext.Provider>
            </UserContext.Provider>
        </div>
    );
}

export default App;
