import * as React from 'react';

import {UserContext} from "../contexts"
import {useContext, useState} from "react";
import {LoginPopup} from "./LoginPopup/LoginPopup";

import Avatar from "@mui/joy/Avatar";
import Menu from '@mui/joy/Menu';
import MenuItem, {menuItemClasses} from '@mui/joy/MenuItem';
import MenuButton from '@mui/joy/MenuButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListDivider from '@mui/joy/ListDivider';
import Dropdown from '@mui/joy/Dropdown';
import {firebase} from "../firebaseConfig";
import {Logout} from "@mui/icons-material";


export const UserDisplay = () => {
    const user = useContext(UserContext);

    const logout = () => {
        firebase.auth().signOut();
    }

    if (user.value.uid) {
        const displayName = user.value.displayName ? user.value.displayName : user.value.email

        // no-referrer to fix access problems with avatar image
        return <Dropdown style={{fontWeight: "inherit", fontSize: "inherit", width: "inherit"}}>
            <MenuButton size="sm" variant="plain" style={{fontWeight: "inherit", fontSize: "inherit"}} className="no-background">
                <Avatar slotProps={{img: {referrerPolicy: "no-referrer"}}} src={user.value.photoURL} size="sm"/><span style={{width: ".7em"}}/>{displayName}
            </MenuButton>
            <Menu sx={{backgroundColor: theme => theme.colorSchemes.light.palette.background.popup}}>
                <MenuItem onClick={logout}><Logout/> Logout</MenuItem>
            </Menu>
        </Dropdown>
    } else {
        return <LoginPopup/>
    }
}