import {imageURL} from "../../globals";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import React, {PropsWithChildren} from "react";
import CardContent from "@mui/joy/CardContent";

export const ErrorDisplay = (props: PropsWithChildren<{title: string, image: string, text?: string}>) => {
    return <Card variant="soft" sx={{margin: "auto", backgroundColor: "background.level1", maxWidth: "50%"}}>
        <img src={imageURL(props.image)} alt={props.title}/>
        <CardContent>
            <Typography level="h1" sx={{textAlign: "center"}}>{props.title}</Typography>
            <Typography level="body-md" sx={{textAlign: "center"}}>{props.text}</Typography>
        </CardContent>
        {props.children}
    </Card>
}