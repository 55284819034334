import Card from '@mui/joy/Card';
import Grid from '@mui/joy/Grid';
import {imageURL, server, thumbnailURL} from "../globals";
import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import {VerticalPlaceholder} from "./VerticalPlaceholder";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../contexts";


export const TopicCard = (props: {topic: any, path: string}) => {
    const target: any = `/index/${props.path}/${props.topic.path}`.replace("//", "/")
    const navigate = useNavigate();

    return <Grid xs={12} md={6} lg={4}>
        <div style={{cursor: "pointer", height: "calc(100% - 2em)"}} onClick={() => navigate(target)}>
            <Card variant="soft" style={{height: "100%"}}>
                <img src={thumbnailURL(props.topic.active_image)} alt={props.topic.path}/>
                <CardContent>
                    <Typography level="title-md">{props.topic.path}</Typography>
                    <VerticalPlaceholder height="1em"/>
                    <Chip className="chip-primary" size="lg">{props.topic.contained_texts} Texts</Chip>
                    <VerticalPlaceholder height="1em"/>
                    <Typography level="body-sm">{props.topic.flavor ? props.topic.flavor : ""}</Typography>
                </CardContent>
            </Card>
        </div>
    </Grid>
}