import {Typography} from "@mui/joy";

export const TimestampDisplay = (props: {seconds: number}) => {
    const hours = () => Math.floor(props.seconds/3600)
    const minutes = () => Math.floor((props.seconds % 3600)/60)
    const seconds = () => Math.floor(props.seconds % 60)

    const pad = (raw: number) => {
        return ('0' + raw.toString()).slice(-2)
    }

    if (hours() == 0) {
        return <Typography level="body-sm">{pad(minutes())}:{pad(seconds())}</Typography>
    } else {
        return <Typography level="body-sm">{pad(hours())}:{pad(minutes())}:{pad(seconds())}</Typography>
    }
}