import {LemmaDisplay} from "./LemmaDisplay";
import Tooltip from '@mui/joy/Tooltip';
import {useContext, useEffect, useRef, useState} from "react";
import {SelectionContext} from "../contexts";

export const SentenceDisplay = (props: {sentence: any, index: number}) => {
    const {value, setValue} = useContext(SelectionContext);

    return <Tooltip
        open={value.sentence === props.index && value.lemma === null}
        arrow
        color="warning"
        title={props.sentence.translation}
        variant="soft"
        placement="bottom">
            <span onContextMenu={event => {event.preventDefault(); return false;}}
                  className={value.sentence === props.index && value.lemma === null
                      ? "sentence-display translation"
                      : (value.highlight === props.index && value.showHighlight
                          ? "sentence-display highlight"
                          : "sentence-display"
                      )
                  }>
                {props.sentence.tokens.map((lemma: any, index: number) => <LemmaDisplay key={index} sentenceIndex={props.index} index={index} lemma={lemma}/>)}
            </span>
    </Tooltip>
}