export const langstoriesTheme: any = {
    cssVarPrefix: 'langstories',
    colorSchemes: {
        light: {
            palette: {
                gradient: 'background: rgb(233,255,230); background: linear-gradient(140deg, rgba(233,255,230,1) 0%, rgba(184,221,218,1) 24%, rgba(148,191,202,1) 60%, rgba(101,151,182,1) 100%);',
                neutral: {
                    'softBg': '#dcf0ff'
                },
                primary: {
                    "50": "#ecfeff",
                    "100": "#ccffeb",
                    "200": "#a5f3fc",
                    "300": "#67e8f9",
                    "400": "#22d3ee",
                    "500": "#06b6d4",
                    "600": "#0891b2",
                    "700": "#0e7490",
                    "800": "#155e75",
                    "900": "#164e63"
                },
                background: {
                    body: 'var(--langstories-palette-gradient)',
                    surface: "#ccffeb",
                    popup: "#ccffeb",
                    strongGradient: "background: rgb(101,151,182); background: linear-gradient(0deg, rgba(101,151,182,1) 0%, rgba(184,221,218,1) 100%);",
                    lighterGradient: "background: rgb(148,191,202); background: linear-gradient(0deg, rgba(148,191,202,1) 0%, rgba(233,255,230,1) 100%);",
                    lightGradient: "background: rgb(196,229,237); background: linear-gradient(0deg, rgba(196,229,237,1) 0%, rgba(185,241,255,1) 100%);",
                }
            }
        },
    },
};