import Link from "@mui/joy/Link";
import {LanguageDisplay} from "./LanguageDisplay";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import React, {useContext} from "react";
import {UserContext} from "../contexts";
import {useNavigate} from "react-router-dom";

export const TopicBreadcrumbs = (props: {topic: string}) => {
    const user = useContext(UserContext);
    const navigate = useNavigate()

    const topicPath = props.topic

    return <Breadcrumbs separator="|" style={{padding: ".1em"}}>
        <Link key={user.value.settings.language} color="neutral" onClick={() => navigate("/index")}><LanguageDisplay/></Link>
        {
            ["", "/"].includes(topicPath)
            ? <Link key="All topics" color="neutral" onClick={() => navigate("/index")}>All Topics</Link>
            : topicPath.split("/")
                .map((topicSegment: string, index: number) => {
                    const topicSegmentPath = topicPath.split("/").slice(0, index + 1).join("/")
                    const topicSegmentName = topicPath.split("/")[index]
                    if (topicSegmentPath === "") {
                        return "";
                    }
                    return <Link key={topicSegmentPath} color="neutral" onClick={() => navigate("/index/" + topicSegmentPath)}>{topicSegmentName}</Link>
                })
        }
    </Breadcrumbs>
}