import {firebase} from "./firebaseConfig";


export const defaultSettings = () => {
    return {
        language: "ru",
        has_read: [],
        reading: []
    }
}

export const getUserSettings = (user: any) => {
    return new Promise(async (resolve, reject) => {
        if (!user) {
            // If there is no logged in user, just return default settings
            resolve({settings: defaultSettings()})
            return
        }

        let settings: any = {}

        try {
            const doc = await firebase.firestore().collection("user-settings").doc(user.uid).get()
            if (doc.exists) {
                settings = doc.data()
            }
        } catch (e) {
            // No settings available
        }

        // Overwrite default settings
        user.settings = Object.assign(defaultSettings(), settings)
        resolve(user)
    })
}

export const persistUserSettings = (user: any) => {
    console.log("persisting for user", user)
    return new Promise(async (resolve, reject) => {
        if (!user.uid) {
            // If there is no logged in user, just return default settings
            resolve(null)
            return
        }

        try {
            await firebase.firestore().collection("user-settings").doc(user.uid).set(user.settings)
            console.log("User settings persisted")
        } catch (e) {
            console.log(e)
        }
    })
}

export const isReading = (user: any, text: any) => {
    return text.segments.some((segment: any) => user.settings.reading?.includes(segment.id))
    && !hasRead(user, text)
}

export const hasRead = (user: any, text: any) => {
    return text.segments.every((segment: any) => user.settings.has_read?.includes(segment.id))
}

export const addToReading = (user: any, segment: any) => {
    return new Promise<void>((resolve, reject) => {
        if (!user.value.settings.reading.includes(segment.id)) {
            user.value.settings.reading.push(segment.id)
            user.updateSettings(user.value.settings)
                .then(resolve)
                .catch(reject)
        }

        resolve()
    })
}

export const addToRead = (user: any, segment: any) => {
    return new Promise<void>((resolve, reject) => {
        user.value.settings.has_read.push(segment.id)
        user.updateSettings(user.value.settings)
            .then(resolve)
            .catch(reject)
    })
}

export const removeFromRead = (user: any, segment: any) => {
    return new Promise<void>((resolve, reject) => {
        user.value.settings.has_read = user.value.settings.has_read.filter((item: string) => item !== segment.id)
        user.updateSettings(user.value.settings)
            .then(resolve)
            .catch(reject)
    })
}