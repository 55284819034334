import React from 'react';
import {useNavigate} from "react-router-dom";
import {Card, Typography, Divider, Grid} from "@mui/joy";

export const Footer = () => {
    const navigate = useNavigate()

    const firstYear = 2023
    const currentYear = new Date().getFullYear()
    const yearString = firstYear == currentYear ? firstYear.toString() : `${firstYear} - ${currentYear}`

    return <React.Fragment>
        <Card variant="soft">
            <Grid container>
                <Grid md={4} xs={6}>
                    <h4 className="title-medium">Explore</h4>
                    <a className="link-small" href="/">Home</a>
                    <a className="link-small" href="/index">Browse</a>
                </Grid>
                <Grid md={4} xs={6}>
                    <h4 className="title-medium">Legal</h4>
                    <a className="link-small" href="/imprint">Imprint</a>
                </Grid>
            </Grid>
        </Card>
        <Typography level="body-sm" style={{float: "right"}}>©{yearString} LanguageLibrary</Typography>
    </React.Fragment>
}