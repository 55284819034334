import React from 'react';
import './CornerIcon.css';

interface Props {
    icon: React.ReactElement
    position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
    backgroundColor?: string
}

export const CornerIcon = (props: Props) => {
    const style: any = {
        "--background-color": props.backgroundColor ? props.backgroundColor : "var(--langstories-palette-primary-700)",
        width: "1.5em",
        height: "1.5em"
    }

    return <div className="corner-icon-container">
        <div className="corner-icon-bg" style={{width: style.width, height: style.height}}>
            {props.icon}
        </div>
        <div className={`corner-icon ${props.position}`} style={style}>
            {props.icon}
        </div>
    </div>
}