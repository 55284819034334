import React from 'react';
import {Navbar} from "./Navbar";
import {VerticalPlaceholder} from "./VerticalPlaceholder";
import {Footer} from "./Footer";

export const Page = (props: React.PropsWithChildren & {style?: any}) => {
    const style: any = {width: "60vw", margin: "auto", textAlign: "left"};
    Object.assign(style, props.style);

    return <div style={style}>
        <Navbar/>
        <VerticalPlaceholder height="1.5em"/>
        {props.children}
        <VerticalPlaceholder height="1em"/>
        <Footer/>
    </div>
}