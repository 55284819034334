import React, {useEffect, useState} from 'react';
import './HomePage.css';
import {Page} from "../../components/Page";
import Grid from "@mui/joy/Grid";
import {APP_NAME, discord, imageURL} from "../../globals";
import {Card, List, ListItem, ListItemDecorator, Typography} from "@mui/joy";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";


export const HomePage = () => {
    useEffect(() => {document.title = `${APP_NAME} | Home`}, [])

    return <Page style={{width: "60vw"}}>
        <Grid container spacing={2}>
            <Grid xs={12}><img src={imageURL("app/header")} alt="" style={{width: "100%"}}/></Grid>

            <Grid xs={12}>
                <Card variant="soft">
                    <h3 className="title-large">Welcome to the Language Library!</h3>
                    <Typography level="body-md">
                        <p>We're building towards becoming a go-to place for beginner and intermediate language learners to get started with reading in their target language.</p>
                        <p>Our collection includes classic books and short stories in original and simplified versions. We've also got unique AI-generated conversations and stories for you to dive into.</p>
                        <p>It's a fun, simple way to improve your language skills. So come on in, and start exploring!</p>

                        <button className="discord-button" onClick={() => window.open(discord, "_blank")}><img src="/discord-logo.png" alt=""/>Come Chat on Discord</button>
                    </Typography>
                </Card>
                <VerticalPlaceholder height=".2em"/>
            </Grid>

            <Grid xs={12} md={6} style={{display: 'flex'}}>
                <Card variant="soft" style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: "100%"}}>
                    <h4 className="title-medium">What's new</h4>
                    <List>
                        <ListItem>
                            <ListItemDecorator>⃘</ListItemDecorator> This website :)
                        </ListItem>
                    </List>
                </Card>
            </Grid>

            <Grid xs={12} md={6} style={{display: 'flex'}}>
                <Card variant="soft">
                    <h4 className="title-medium">Roadmap</h4>
                    <List>
                        <ListItem>
                            <ListItemDecorator>⃘</ListItemDecorator> More texts in more languages - reach out if you'd like to see something added in particular!
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>⃘</ListItemDecorator> Keeping track of what you have already read
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>⃘</ListItemDecorator> Translation features for your own texts
                        </ListItem>
                    </List>
                </Card>
            </Grid>
        </Grid>
    </Page>
}