import React, {useContext, useEffect, useState} from 'react';
import './IndexPage.css';
import {Page} from "../../components/Page";
import Grid from '@mui/joy/Grid';
import {TextCard} from "../../components/TextCard";
import {TopicCard} from "../../components/TopicCard";
import {useParams} from "react-router-dom";
import {UserContext} from "../../contexts";
import {server} from "../../globals";
import {BookCard} from "../../components/BookCard";
import {TopicBreadcrumbs} from "../../components/TopicBreadcrumbs";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import LinearProgress from "@mui/joy/LinearProgress";

export const IndexPage = () => {
    const [topic, setTopic] = useState<any>(null);

    const params = useParams();
    const user = useContext(UserContext);

    const topicPath = params["*"] as string
    const topicName: any = topicPath.split("/").pop()
    const indexPath: string = `${user.value.settings.language}/${topicPath}/meta.json`;

    // Set Page title
    useEffect(() => {
        console.log("topicName", topicName)
        if (topicName === "") {
            document.title = "All Texts"
        } else {
            document.title = topicName
        }
    }, [params])

    useEffect(() => {
        const url = `${server}/texts/${indexPath}`
        fetch(url)
            .then(res => res.json())
            .then(res => setTopic(res))
    }, [indexPath]);

    if (!topic) {
        return <Page><VerticalPlaceholder height="5em"/><LinearProgress /></Page>
    }

    return <Page style={{width: "60vw"}}>
        <div onContextMenu={event => {event.preventDefault(); return false;}}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={12}>
                    <TopicBreadcrumbs topic={topicPath}/>
                </Grid>
                {topic.topics?.map((topic: any, i: number) => <TopicCard key={i} topic={topic} path={topicPath}/>)}
                {topic.texts?.map((text: any, i: number) => text.text_type === "Book" ? <BookCard key={i} text={text} path={topicPath}/> : <TextCard key={i} text={text} path={topicPath}/>)}
            </Grid>
        </div>
    </Page>
}