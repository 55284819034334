import Card from '@mui/joy/Card';
import Grid from '@mui/joy/Grid';
import {imageURL, server, thumbnailURL} from "../globals";
import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import {VerticalPlaceholder} from "./VerticalPlaceholder";
import {useContext} from "react";
import {UserContext} from "../contexts";
import {useNavigate} from "react-router-dom";


export const LanguageCard = (props: {language: any, history?: any}) => {
    const user = useContext(UserContext)
    const navigate = useNavigate()

    const selectLanguage = () => {
        const target: any = "/index"
        user.updateSettings({...user.value.settings, language: props.language.path})
        navigate(target)
    }

    return <Grid md>
        <div style={{cursor: "pointer", height: "calc(100% - 2em)"}} onClick={selectLanguage}>
            <Card variant="soft" style={{height: "100%"}} color="neutral">
                <img src={thumbnailURL(props.language.active_image)} alt={props.language.name}/>
                <CardContent>
                    <Typography level="title-lg">{props.language.display_name ? props.language.display_name : props.language.path}</Typography>
                    <VerticalPlaceholder height="1em"/>
                    <Chip className="chip-primary" size="lg">{props.language.contained_texts} Texts</Chip>
                    <VerticalPlaceholder height="1em"/>
                    <Typography level="body-sm">{props.language.flavor ? props.language.flavor : ""}</Typography>
                </CardContent>
            </Card>
        </div>
    </Grid>
}