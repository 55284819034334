import React, {useEffect, useState} from 'react';
import './LanguagePage.css';
import {Page} from "../../components/Page";
import Grid from '@mui/joy/Grid';
import {APP_NAME, server} from "../../globals";
import {LanguageCard} from "../../components/LanguageCard";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import LinearProgress from "@mui/joy/LinearProgress";

export const LanguagePage = () => {
    const [topic, setTopic] = useState<any>(null);

    useEffect(() => {document.title = `${APP_NAME} | Languages`}, [])

    useEffect(() => {
        const url = `${server}/texts/meta.json`
        fetch(url)
            .then(res => res.json())
            .then(res => setTopic(res))
    }, []);

    if (!topic) {
        return <Page><VerticalPlaceholder height="5em"/><LinearProgress /></Page>
    }

    return <Page style={{width: "60vw"}}>
        <div onContextMenu={event => {event.preventDefault(); return false;}}>
            <Grid container spacing={2} sx={{ flexGrow: 1}} alignItems="stretch">
                {Object.entries(topic.topics).map(([langCode, langTopic]: any, i: number) => <LanguageCard key={i} language={langTopic}/>)}
            </Grid>
        </div>
    </Page>
}