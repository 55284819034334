import * as React from 'react';

import Modal from '@mui/joy/Modal';
import Avatar from '@mui/joy/Avatar';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Person from '@mui/icons-material/Person';

import {useContext, useState} from "react";
import {UserContext} from "../../contexts";
import {signInWithGoogle, signInWithPassword, createAccount} from "../../firebaseConfig";
import {DialogContent, TabList, Tabs, Tab, TabPanel, Input, Button} from "@mui/joy";
import {PasswordInput} from "./PasswordInput";
import {Mail} from "@mui/icons-material";
import Stack from "@mui/joy/Stack";
import {VerticalPlaceholder} from "../VerticalPlaceholder";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";


export const LoginPopup = () => {
    const context = useContext(UserContext);

    const [modalOpen, setModelOpen] = useState(false)

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [mail, setMail] = useState("")

    const handleSignedIn = (user: any) => {
        context.update(user)
        setModelOpen(false)
    }

    return <React.Fragment>
        <Button size="sm" variant="plain" className="no-background"
                style={{fontWeight: "inherit", fontSize: "inherit", color: "var(--langstories-palette-neutral-800)"}}
                onClick={() => setModelOpen(true)}>
            <Avatar size="sm"><Person/></Avatar><span style={{width: ".7em"}}/>Sign In
        </Button>
        <Modal open={modalOpen} style={{zIndex: 9999}}>
            <ModalDialog
                sx={{
                    width: "60vw",
                    zIndex: 9999,
                    backgroundColor: theme => theme.colorSchemes.light.palette.primary["50"]
                }}
                variant="plain"
                color="primary">
                <ModalClose onClick={() => setModelOpen(false)}/>
                <VerticalPlaceholder height=".5em"/>
                <DialogContent>
                    <Tabs aria-label="Sign In Tabs" defaultValue={0}>
                        <TabList>
                            <Tab>Log In</Tab>
                            <Tab>Create account</Tab>
                        </TabList>
                        <TabPanel value={0}>
                            <Stack spacing={1}>
                                <Input startDecorator={<Mail/>} placeholder="E-Mail" value={mail}
                                       onChange={(event: any) => setMail(event.target.value)}/>
                                <PasswordInput value={password} setValue={setPassword}/>
                                <Button onClick={() => signInWithPassword(mail, password).then(handleSignedIn)}>Log
                                    In</Button>

                                <VerticalPlaceholder height=".15em"/>
                                <Divider sx={{"--Divider-lineColor": theme => theme.colorSchemes.light.palette.neutral["400"]}}>
                                    <Chip sx={{backgroundColor: theme => theme.colorSchemes.light.palette.neutral["300"]}}>OR</Chip>
                                </Divider>
                                <VerticalPlaceholder height=".15em"/>

                                <Button
                                    className="google-button"
                                    startDecorator={<img width={26} src="/g-logo.png" alt="Google logo"/>}
                                    onClick={() => signInWithGoogle().then(handleSignedIn)}>
                                    <span style={{width: "100%", fontFamily: "Roboto"}}>Sign in with Google</span>
                                </Button>
                            </Stack>
                        </TabPanel>
                        <TabPanel value={1}>
                            <Stack spacing={1}>
                                <Input startDecorator={<Mail/>} placeholder="E-Mail" value={mail}
                                       onChange={(event: any) => {
                                           setMail(event.target.value)
                                       }}/>

                                <PasswordInput strengthIndicator={true} value={password} setValue={setPassword}/>
                                <PasswordInput placeholder="Confirm Password" value={confirmPassword}
                                               setValue={setConfirmPassword}/>

                                <Button onClick={() => createAccount(mail, password).then(handleSignedIn)}>Create
                                    Account</Button>

                                <VerticalPlaceholder height=".15em"/>
                                <Divider sx={{"--Divider-lineColor": theme => theme.colorSchemes.light.palette.neutral["400"]}}>
                                    <Chip sx={{backgroundColor: theme => theme.colorSchemes.light.palette.neutral["300"]}}>OR</Chip>
                                </Divider>
                                <VerticalPlaceholder height=".15em"/>

                                <Button
                                    className="google-button"
                                    startDecorator={<img width={26} src="/g-logo.png" alt="Google logo"/>}
                                    onClick={() => signInWithGoogle().then(handleSignedIn)}>
                                    <span style={{width: "100%", fontFamily: "Roboto"}}>Sign in with Google</span>
                                </Button>

                            </Stack>
                        </TabPanel>
                    </Tabs>
                </DialogContent>
            </ModalDialog>
        </Modal>
    </React.Fragment>
}