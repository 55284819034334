import {useRef, useEffect, useState, useContext} from "react";
import {Howl} from 'howler';
import {audioURL} from "../globals";
import {Avatar, Card, Grid, Slider, Stack} from "@mui/joy";
import {Pause, PlayArrow, SkipNext, SkipPrevious, Speed, VolumeDown, VolumeMute, VolumeUp} from "@mui/icons-material";
import "./AudioPlayer.css"
import {TimestampDisplay} from "./TimestampDisplay";
import {SelectionContext} from "../contexts";


export const AudioPlayer = (props: { audioID: string, transcript: any }) => {
    const {value, setValue} = useContext(SelectionContext);

    const [howl, setHowl] = useState<any>(null)
    const [syncInterval, setSyncInterval] = useState<any>(null)

    // These states are for display purposes only. Source of truth is the Howl instance
    const [volume, setVolume] = useState<number>(.5);
    const [speed, setSpeed] = useState<number>(1);
    const [playbackPosition, setPlaybackPosition] = useState<number>(0);
    const [length, setLength] = useState<number>(1);
    const [playing, setPlaying] = useState<boolean>(false);

    const currentSentenceIndex = (sound: any) => {
        if (!props.transcript || !sound) {
            return null;
        }

        if (!props.transcript.segments) {
            console.log(props.transcript)
        }

        const position = sound.seek()

        for (let i = 1; i < props.transcript.length; ++i) {
            const [timestamp, sentence] = props.transcript[i]
            if (position < (timestamp / 1000)) {
                return i - 1
            }
        }

        return props.transcript.length - 1
    }

    const currentSentence = (sound: any) => {
        if (!props.transcript || !sound) {
            return null;
        }

        const index = currentSentenceIndex(sound)

        if (index === null || index === undefined) {
            return null
        }

        return props.transcript[index]
    }

    const seek = (position: number) => {
        if (!howl) {
            return;
        }

        howl.seek(position)
        setPlaybackPosition(position)
    }

    const next = (sound: any) => {
        if (!sound || currentSentenceIndex(sound) === null || !props.transcript || currentSentenceIndex(sound) === props.transcript.length - 1) {
            return
        }

        const [timestamp, sentence] = props.transcript[currentSentenceIndex(sound)! + 1]
        seek(timestamp / 1000)
    }

    const previous = (sound: any) => {
        if (!sound || !props.transcript) {
            return
        }

        const [timestamp, sentence] = currentSentence(sound)
        // @ts-ignore
        const previousIndex = currentSentenceIndex(sound) - 1

        if (previousIndex < 0 || sound.seek() - (timestamp / 1000) >= 2) {
            sound.seek(timestamp / 1000)
        } else {
            const [previousTimestamp, previousSentence] = props.transcript[previousIndex]
            sound.seek(previousTimestamp / 1000)
        }
    }

    const updateSpeed = (speed: number) => {
        if (!howl) {
            return;
        }

        howl.rate(speed)
        setSpeed(speed)
    }

    const updateVolume = (volume: number) => {
        if (!howl) {
            return;
        }

        howl.volume(volume)
        setVolume(volume)
    }

    const togglePlay = () => {
        if (playing) {
            howl.pause()
        } else {
            howl.play()
        }
        setPlaying(!playing)
    }

    const volumeIcon = () => {
        if (volume === 0) {
            return <VolumeMute/>
        }
        if (volume < .5) {
            return <VolumeDown/>
        }
        return <VolumeUp/>
    }

    const syncAudio = (sound: any, selection: any) => {
        if (!sound) {
            return
        }

        setLength(sound.duration())
        if (sound.playing()) {
            setPlaybackPosition(sound.seek())
            if (selection.highlight !== currentSentenceIndex(sound)) {
                setValue({...selection, highlight: currentSentenceIndex(sound)})
            }
        }
    }

    useEffect(() => {
        const sound = new Howl({
            src: [audioURL(props.audioID)],
            autoplay: false,
            loop: false,
            volume: volume,
            rate: speed,
            onend: function () {
                setPlaying(false);
                console.log('Finished playing');
            }
        });

        setHowl(sound)

        return () => {
            sound.unload(); // This will unload the sound and prevent memory leaks
        };
    }, [props.audioID]);

    useEffect(() => {
        if (syncInterval !== null) {
            clearInterval(syncInterval)
        }

        const interval = setInterval(() => syncAudio(howl, value), 200)
        setSyncInterval(interval)

        return () => {
            clearInterval(interval)
        }

    }, [howl, value])

    // Clear selection when loading a new text
    useEffect(() => {
        setValue({...value, highlight: null})
    }, [props.audioID])

    return <Card sx={{
        backgroundColor: (theme: any) => theme.colorSchemes.light.palette.background.lightGradient,
        width: "80%",
        margin: "auto"
    }}>
        <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
            <TimestampDisplay seconds={playbackPosition}/>
            <Slider
                aria-label="Playback Position"
                value={playbackPosition}
                onChange={(event: any) => seek(event.target.value)}
                max={length}
                min={0}
                step={1}
            />
            <TimestampDisplay seconds={length - playbackPosition}/>
        </Stack>

        <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center" justifyContent="center">
            <Avatar color="primary" variant="solid" size="md" onClick={() => previous(howl)}
                    sx={{cursor: "pointer"}}><SkipPrevious/></Avatar>
            {
                playing ? <Avatar color="primary" variant="outlined" size="lg" onClick={togglePlay}
                                  sx={{cursor: "pointer"}}><Pause/></Avatar>
                    : <Avatar color="primary" variant="solid" size="lg" onClick={togglePlay}
                              sx={{cursor: "pointer"}}><PlayArrow/></Avatar>
            }
            <Avatar color="primary" variant="solid" size="md" onClick={() => next(howl)}
                                  sx={{cursor: "pointer"}}><SkipNext/></Avatar>
        </Stack>

        <Grid container>
            <Grid xs={4}>
                <Stack spacing={4} direction="row" sx={{mb: 1}} alignItems="center">
                    <Speed/>
                    <Slider
                        aria-label="Speed"
                        step={.05}
                        min={.5}
                        max={1.5}
                        value={speed}
                        onChange={(event: any) => updateSpeed(event.target.value)}
                        color="neutral"
                        marks
                    />
                    <span style={{marginLeft: "1em"}}>{speed.toFixed(2)}x</span>
                </Stack>
            </Grid>
            <Grid xs={4}/>
            <Grid xs={4}>
                <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
                    {volumeIcon()}
                    <Slider
                        aria-label="Volume"
                        min={0}
                        max={100}
                        value={volume * 100}
                        onChange={(event: any) => updateVolume(event.target.value / 100)}
                        color="neutral"
                    />
                </Stack>
            </Grid>
        </Grid>
    </Card>
}