import Tooltip from '@mui/joy/Tooltip';
import {useContext, useState} from "react";
import {SelectionContext} from "../contexts";

export const LemmaDisplay = (props: {lemma: any, sentenceIndex: number, index: number}) => {
    const {value, setValue} = useContext(SelectionContext);

    const [lastClickTime, setLastClickTime] = useState(0);

    const showLemmaTranslation = (selection: any) => {
        const newSelection = {...selection, sentence: props.sentenceIndex, lemma: props.index}
        setValue(newSelection)
    }

    const showSentenceTranslation = (selection: any) => {
        const newSelection = {...selection, sentence: props.sentenceIndex, lemma: null}
        setValue(newSelection)
    }

    const onMouseUp = (event: any, selection: any) => {
        if (event.button === 0 || event.button === 2) {
            const timeSinceLastClick = Date.now() - lastClickTime;

            if (timeSinceLastClick <= 800 || event.button === 2) {
                // For double click or right click, display sentence translation
                showSentenceTranslation(selection);
            } else {
                // For regular clicks, show lemma translation and set timer for double click tracking
                showLemmaTranslation(selection);
                setLastClickTime(Date.now())
            }


            event.preventDefault();
            return false;
        }
    }

    const lemmaSelected = (selection: any) => {
        return selection.sentence === props.sentenceIndex && selection.lemma === props.index
    }

    if (!props.lemma.lemma_translation) {
        return <span>{props.lemma.display}</span>;
    }

    return <Tooltip
            arrow
            color="primary"
            title={`${props.lemma.lemma}: ${props.lemma.lemma_translation}`}
            variant="soft"
            placement="top"
            open={lemmaSelected(value)}>
        <span onMouseUp={(event: any) => onMouseUp(event, value)}
              onContextMenu={event => {event.preventDefault(); return false;}}
              className={lemmaSelected(value) ? "lemma-display translation" : "lemma-display"}>
                {props.lemma.display}
        </span>
    </Tooltip>;
}