export const languageFlags: any = {
    "ru": "ru",
    "de": "de",
    "hu": "hu"
}

export const defaultLicense = "CC BY-SA 4.0"
export const server = process.env.NODE_ENV === 'development' ? "http://localhost:4415" : "https://api.languagelibrary.net"
export const discord = "https://discord.gg/XtPN8VTf8e"

export const imageURL = (imageID: string, useFallback: boolean = true) => {
    if (imageID) {
        const image = imageID.endsWith(".png") ? imageID : imageID + ".png"
        return `${server}/images/${image}`
    } else if (useFallback) {
        return `${server}/images/app/placeholder.png`
    } else {
        console.error("Empty image ID cannot be converted to image URL")
        return ""
    }
}

export const thumbnailURL = (imageID: string, useFallback: boolean = true) => {
    if (imageID) {
        const image = imageID.endsWith(".jpg") ? imageID : imageID + ".jpg"
        return `${server}/thumbnails/${image}`
    } else if (useFallback) {
        return `${server}/thumbnails/app/placeholder.jpg`
    } else {
        console.error("Empty thumbnail ID cannot be converted to thumbnail URL")
        return ""
    }
}

export const audioURL = (audioID: string) => {
    if (audioID) {
        const audio = audioID.endsWith(".mp3") ? audioID : audioID + ".mp3"
        return `${server}/audio/${audio}`
    } else {
        console.error("Empty audio ID cannot be converted to audio URL")
        return ""
    }
}

export const transcriptURL = (audioID: string) => {
    if (audioID) {
        const audio = audioID.endsWith(".json") ? audioID : audioID + ".json"
        return `${server}/transcripts/${audio}`
    } else {
        console.error("Empty audio ID cannot be converted to transcript URL")
        return ""
    }
}

export const APP_NAME = "Language Library"