import React, {useEffect, useState} from 'react';
import {Page} from "../../components/Page";
import {Card} from "@mui/joy";
import {APP_NAME} from "../../globals";


export const ImprintPage = () => {
    useEffect(() => {document.title = `${APP_NAME} | Imprint`}, [])

    return <Page>
        <Card variant="soft">
            <div className='impressum'>
                <h1>Impressum</h1>
                <p>Angaben gemäß § 5 TMG</p>
                <p>Jakob Höfner <br/>
                    Odenwaldstraße 4<br/>
                    64521 Groß-Gerau <br/>
                </p>
                <p>
                    <strong>Vertreten durch: </strong><br/>
                    Jakob Höfner<br/>
                </p>
                <p><strong>Kontakt:</strong> <br/>
                    Telefon: 0152-59588849<br/>
                    E-Mail: <a href='mailto:contact@languagelibrary.net'>contact@languagelibrary.net</a>
                </p>
                <p>
                    <strong>Haftungsausschluss: </strong><br/><br/><strong>Haftung für Inhalte</strong><br/><br/>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
                    Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1
                    TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
                    wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                    überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                    Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                    Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                    möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                    entfernen.<br/><br/><strong>Haftung für Links</strong><br/><br/>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
                    können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
                    stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                    Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                    Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Links umgehend entfernen.<br/><br/><strong>Urheberrecht</strong><br/><br/>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
                    des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
                    Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                    dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
                    Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                    werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Inhalte umgehend entfernen.<br/><br/><strong>Datenschutz</strong><br/><br/>
                    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren
                    Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies,
                    soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an
                    Dritte weitergegeben. <br/>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                    möglich. <br/>
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
                    nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen.
                    Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
                    Werbeinformationen, etwa durch Spam-Mails, vor.<br/>
                </p>
            </div>

            <div className='impressum'>
                <h1>Legal Notice</h1>
                <p>Information according to § 5 TMG</p>
                <p>Jakob Höfner <br/>
                    Odenwaldstraße 4<br/>
                    64521 Groß-Gerau <br/>
                </p>
                <p>
                    <strong>Represented by: </strong><br/>
                    Jakob Höfner<br/>
                </p>
                <p><strong>Contact:</strong> <br/>
                    Phone: 0152-59588849<br/>
                    Email: <a href='mailto:contact@languagelibrary.net'>contact@languagelibrary.net</a>
                </p>
                <p>
                    <strong>Disclaimer: </strong><br/><br/><strong>Liability for Contents</strong><br/><br/>
                    The contents of our pages have been created with the utmost care. However, we cannot guarantee the accuracy,
                    completeness, or up-to-dateness of the contents. As a service provider, we are responsible for our own content on
                    these pages according to general laws as per § 7 Abs.1 TMG. According to §§ 8 to 10 TMG, however, we as a service
                    provider are not obliged to monitor transmitted or stored external information or to investigate circumstances that
                    indicate illegal activity. Obligations to remove or block the use of information under general law remain unaffected.
                    However, liability in this regard is only possible from the time of knowledge of a specific infringement. Upon
                    notification of such violations, we will remove this content immediately.<br/><br/><strong>Liability for Links</strong><br/><br/>
                    Our offer contains links to external websites of third parties, over whose content we have no influence. Therefore,
                    we cannot assume any liability for these external contents. The respective provider or operator of the pages is
                    always responsible for the content of the linked pages. The linked pages were checked for possible legal violations
                    at the time of linking. Illegal content was not recognizable at the time of linking. However, a permanent control of
                    the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. Upon
                    notification of violations, we will remove such links immediately.<br/><br/><strong>Copyright</strong><br/><br/>
                    The contents and works created by the site operators on these pages are subject to German copyright law. Duplication,
                    editing, distribution, and any kind of exploitation outside the limits of copyright require the written consent of
                    the respective author or creator. Downloads and copies of this page are only permitted for private, non-commercial
                    use. As far as the contents on this page were not created by the operator, the copyrights of third parties are
                    respected. In particular, third-party content is identified as such. Should you still become aware of a copyright
                    infringement, please notify us accordingly. Upon notification of violations, we will remove such content
                    immediately.<br/><br/><strong>Data Protection</strong><br/><br/>
                    The use of our website is usually possible without providing personal data. As far as personal data (such as name,
                    address or email addresses) are collected on our pages, this is, as far as possible, always done on a voluntary
                    basis. These data will not be disclosed to third parties without your explicit consent. <br/>
                    We point out that data transmission over the Internet (e.g., when communicating by email) can have security
                    gaps. Complete protection of data against access by third parties is not possible. <br/>
                    The use of contact data published within the bounds of the imprint obligation by third parties for sending
                    unsolicited advertising and information materials is hereby expressly excluded. The operators of the pages
                    expressly reserve the right to take legal action in the event of unsolicited sending of advertising information,
                    such as spam emails.<br/>
                </p>
            </div>
        </Card>
    </Page>
}