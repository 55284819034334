import React, {useEffect, useState} from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import {LanguageDisplay} from "./LanguageDisplay";
import {useNavigate} from "react-router-dom";
import {UserDisplay} from "./UserDisplay";
import {Dropdown, Menu, MenuButton, MenuItem} from "@mui/joy";
import {discord} from "../globals";

export const Navbar = () => {
    const navigate = useNavigate()

    return <Box component="nav" aria-label="Langstories" sx={{flexGrow: 1}}>
        <List role="menubar" orientation="horizontal">
            <ListItem role="none">
                <ListItemButton
                    role="menuitem"
                    component="a"
                    aria-label="Home"
                    onClick={() => navigate("/")}
                    className="navbar-item"
                >
                    Home
                </ListItemButton>
            </ListItem>
            <ListDivider/>
            <ListItem role="none">
                <ListItemButton role="menuitem" component="a" aria-label="Browse" className="navbar-item"
                                onClick={() => navigate("/index")}>
                    Browse
                </ListItemButton>
            </ListItem>
            <ListDivider/>
            <ListItem role="none">
                <Dropdown>
                    <MenuButton size="sm" variant="plain" style={{fontWeight: "inherit", fontSize: "inherit", padding: 0}} className="no-background">About</MenuButton>
                    <Menu sx={{backgroundColor: theme => theme.colorSchemes.light.palette.background.popup}}>
                        <MenuItem onClick={() => navigate("/imprint")}>Imprint</MenuItem>
                        <MenuItem onClick={() => window.open(discord, "_blank")}>Discord</MenuItem>
                    </Menu>
                </Dropdown>
            </ListItem>

            <ListItem role="none" sx={{marginInlineStart: 'auto'}}>
                <ListItemButton
                    role="menuitem"
                    component="a"
                    aria-label="Languages"
                    onClick={() => navigate("/languages")}
                    className="navbar-item"
                >
                    <LanguageDisplay/> Switch Language
                </ListItemButton>
            </ListItem>
            <ListDivider/>
            <ListItem role="none" sx={{float: 'right'}}>
                <ListItemButton className="navbar-item">
                    <UserDisplay/>
                </ListItemButton>
            </ListItem>
        </List>
    </Box>
}