import * as React from 'react';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import LinearProgress from '@mui/joy/LinearProgress';
import Typography from '@mui/joy/Typography';
import Key from '@mui/icons-material/Key';
import {Visibility, VisibilityOff} from "@mui/icons-material";

export const PasswordInput = (props: { value: string, setValue: any, strengthIndicator?: boolean, placeholder?: string }) => {
    const [show, setShow] = React.useState(false);

    const minLength = 12;

    return (
        <Stack
            spacing={0.5}
            sx={{
                '--hue': Math.min(props.value.length * 10, 120),
            }}
        >
            <Input
                type={show ? "text" : "password"}
                placeholder={props.placeholder ? props.placeholder : "Password"}
                startDecorator={<Key/>}
                value={props.value}
                onChange={(event) => props.setValue(event.target.value)}
                endDecorator={
                    show ? <VisibilityOff style={{cursor: "pointer"}} onClick={() => setShow(false)}/>
                         : <Visibility style={{cursor: "pointer"}} onClick={() => setShow(true)}/>
                }
            />

            {
                props.value.length > 0 && props.strengthIndicator ? <React.Fragment>
                    <LinearProgress
                        determinate
                        size="sm"
                        value={Math.min((props.value.length * 100) / minLength, 100)}
                        sx={{
                            bgcolor: 'background.level3',
                            color: 'hsl(var(--hue) 80% 40%)',
                        }}
                    />

                    <Typography
                        level="body-xs"
                        sx={{alignSelf: 'flex-end', color: 'hsl(var(--hue) 80% 30%)'}}
                    >
                        {props.value.length < 3 && 'Very weak'}
                        {props.value.length >= 3 && props.value.length < 6 && 'Weak'}
                        {props.value.length >= 6 && props.value.length < 10 && 'Strong'}
                        {props.value.length >= 10 && 'Very strong'}
                    </Typography>
                </React.Fragment> : ""
            }
        </Stack>
    );
}