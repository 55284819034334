export const splitParagraphs = (translation: any): any[] => {
    const paragraphs = []
    let currentParagraph = []

    for (const sentence of translation) {
        currentParagraph.push(sentence)

        // If the sentence ends in a (single or double) newline, it should end the paragraph
        if (["\n", "\n\n"].includes(sentence.tokens[sentence.tokens.length - 1].lemma)) {
            sentence.tokens[sentence.tokens.length - 1].display = "" // No need to display the line break in any way as this is handled by the paragraph

            // Start a new paragraph
            paragraphs.push(currentParagraph)
            currentParagraph = []
        }
    }

    if (currentParagraph.length > 0) {
        paragraphs.push(currentParagraph)
    }

    return paragraphs
}