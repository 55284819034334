import {splitParagraphs} from "../util/texts";
import React, {useContext, useEffect} from "react";
import Skeleton from "@mui/joy/Skeleton";
import {SentenceDisplay} from "./SentenceDisplay";
import {SelectionContext} from "../contexts";

export const TextSegmentDisplay = (props: {segment: any}) => {
    const {value, setValue} = useContext(SelectionContext);

    const renderText = () => {
        let sentenceIndex = 0
        return splitParagraphs(props.segment.translation).map(
                    (paragraph: any, paragraphIndex: number) => <p key={paragraphIndex}>
                        {paragraph.map((sentence: any) => {
                            const display = <SentenceDisplay key={sentenceIndex} index={sentenceIndex} sentence={sentence}/>
                            sentenceIndex++
                            return display
                        })}
                    </p>
                )
    }

    useEffect(() => {
        const hideTranslation = () => {
            // Set selected sentence and lemma to null to deselect
            setValue({...value, sentence: null, lemma: null})
        }

        document.addEventListener("mousedown", hideTranslation);
        return () => document.removeEventListener("mousedown", hideTranslation);
    }, [value]);

    return <Skeleton loading={!props.segment}>
        {
            props.segment
                ? renderText()
                : "This text is currently being loaded..."
        }
    </Skeleton>
}