import React from "react";
import {ErrorDisplay} from "./ErrorDisplay";
import {Button} from "@mui/joy";
import {useNavigate} from "react-router-dom";

export const TextNotFoundError = () => {
    const navigate = useNavigate()

    return <ErrorDisplay image="app/not_found" title="We could not find this Page" text="It may have been moved or deleted, or if you entered an URL manually, it may have a typo.">
        <Button color="primary" sx={{backgroundColor: "var(--langstories-palette-primary-800)"}} onClick={() => navigate("/")}>Go Back Home</Button>
    </ErrorDisplay>
}