import React, {useContext} from 'react';
import Card from '@mui/joy/Card';
import Grid from "@mui/joy/Grid";
import {imageURL, server, thumbnailURL} from "../globals";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import {ReadingDifficultyBadge} from "./ReadingDifficultyBadge";
import {VerticalPlaceholder} from "./VerticalPlaceholder";
import {useNavigate} from "react-router-dom";
import {SentenceDisplay} from "./SentenceDisplay";
import {hasRead, isReading} from "../settings";
import {CornerIcon} from "./CornerIcon";
import {AutoStories, Done} from "@mui/icons-material";
import {UserContext} from "../contexts";


export const BookCard = (props: {text: any, path: string}) => {
    const user = useContext(UserContext)
    const target: any = `/book/${user.value.settings.language}/${props.path}/${props.text.topic}`.replace("//", "/")
    const navigate = useNavigate();

    const readStatusIndicator = () => {
        if (hasRead(user.value, props.text)) {
            return <CornerIcon backgroundColor="var(--langstories-palette-success-700)" position="top-left" icon={<Done sx={{color: "white", width: "1em", height: "1em"}}/>}/>
        }

        if (isReading(user.value, props.text)) {
            return <CornerIcon position="top-left" icon={<AutoStories sx={{color: "white"}}/>}/>
        }

        return <React.Fragment/>
    }

    return <Grid xs={12} md={6} lg={4}>
        <div style={{cursor: "pointer", height: "calc(100% - 2em)"}} onClick={() => navigate(target)}>
            <Card variant="soft" style={{height: "100%", overflow: "hidden"}}>
                {readStatusIndicator()}

                <img src={thumbnailURL(props.text.segments[0].active_image)} alt={props.text.name}/>
                <CardContent>
                    <Typography level="body-sm">{props.text.author}</Typography>
                    <Typography level="title-md">{props.text.title_translation ? <SentenceDisplay index={-1} sentence={props.text.title_translation[0]}/> : props.text.title}</Typography>

                    <VerticalPlaceholder height="1em"/>
                    <ReadingDifficultyBadge score={props.text.difficulty_score}/>
                    <VerticalPlaceholder height="1em"/>
                    <Typography level="body-sm">{props.text.summary ? props.text.summary : ""}</Typography>

                    <VerticalPlaceholder height=".5em"/>
                </CardContent>
                <CardOverflow variant="soft">
                    <Divider inset="context"/>
                    <CardContent orientation="horizontal">
                        <Typography level="body-sm">{props.text.segments.length > 1 ? <React.Fragment>{props.text.segments.length} Chapters | </React.Fragment> : ""}{props.text.word_count} Words</Typography>
                    </CardContent>
                </CardOverflow>
            </Card>
        </div>
    </Grid>
}