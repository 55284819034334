import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import {AuthenticatedRoute} from "./AuthenticatedRoute";
import {TextPage} from "../pages/TextPage/TextPage";
import {IndexPage} from "../pages/IndexPage/IndexPage";
import {LanguagePage} from "../pages/LanguagePage/LanguagePage";
import {BookPage} from "../pages/BookPage/BookPage";
import {HomePage} from "../pages/HomePage/HomePage";
import {ErrorPage} from "../pages/ErrorPage/ErrorPage";
import {TextNotFoundError} from "../components/Errors/TextNotFoundError";
import {ImprintPage} from "../pages/ImprintPage/ImprintPage";

export const Routing: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path='/text/:languageCode/*' element={<TextPage/>}/>
                <Route path='/book/:languageCode/*' element={<BookPage/>}/>
                <Route path='/index/*' element={<IndexPage/>}/>
                <Route path='/languages' element={<LanguagePage/>}/>

                <Route element={<AuthenticatedRoute/>}>
                    <Route path='/account'>
                        Test
                    </Route>
                </Route>

                <Route path='/imprint' element={<ImprintPage/>}/>
                <Route path='/' element={<HomePage/>}/>

                <Route path='/*' element={<ErrorPage error={<TextNotFoundError/>}/>}/>
            </Routes>
        </Router>
    );
};
