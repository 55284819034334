import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDRjNefoJn_ZzCpHasDlfYJAa1NUfK-cds",
    authDomain: "informanthik.firebaseapp.com",
    projectId: "informanthik",
    storageBucket: "informanthik.appspot.com",
    messagingSenderId: "487894527286",
    appId: "1:487894527286:web:86ec0eaabc6cb50698d797"
};

firebase.initializeApp(firebaseConfig);

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
    return new Promise<any>((resolve, reject) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
                firebase.auth().signInWithPopup(googleProvider).then((result) => {
                    // The signed-in user info.
                    console.log(result.user)
                    resolve(result.user)
                }).catch(reject);
            })
            .catch(reject);
    })
};

export const createAccount = (mail: string, password: string) => {
    return new Promise<any>((resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(mail, password)
            .then((result) => {
                console.log(result.user)
                resolve(result.user)
            })
            .catch(reject)
    })
}

export const signInWithPassword = (mail: string, password: string) => {
    return new Promise<any>((resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(mail, password)
            .then((result) => {
                console.log(result.user)
                resolve(result.user)
            })
            .catch(reject)
    })
}

export {firebase};