import React from 'react';
import {Typography} from "@mui/joy";
import {defaultLicense} from "../../globals";


// Links to information about certain licenses. Will be displayed for texts using that License.
export const licenseReferences: any = {
    "CC BY-SA 4.0": <React.Fragment>More info: <a href={"https://creativecommons.org/licenses/by-sa/4.0/deed.en"}>Creative Commons Attribution-ShareAlike 4.0 International</a></React.Fragment>,
}

export const SourceDisplay = (props: {text: any}) => {
    if (!props.text) {
        return <React.Fragment/>
    }

    const license = props.text.license ? props.text.license : defaultLicense

    const licenseReference = () => {
        if (!licenseReferences[license]) {
            return <React.Fragment/>
        }

        return <React.Fragment>
            &nbsp;{licenseReferences[license]}
        </React.Fragment>
    }

    const sourceText = () => {
        if (!props.text.source) {
            return <Typography level="body-sm">
                This text is not based on any external sources.
            </Typography>
        }

        return <Typography level="body-sm">
            The original text is sourced from <a href={props.text.source} target="_blank">{decodeURI(props.text.source)}</a>. Please refer to the source page for a list of authors.
        </Typography>
    }

    return <p>
        {sourceText()}
        <Typography level="body-sm">It is licensed under {license}.{licenseReference()}</Typography>
    </p>
}