import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import './TextPage.css';
import {Page} from "../../components/Page";
import {SentenceDisplay} from "../../components/SentenceDisplay";
import Skeleton from '@mui/joy/Skeleton';
import Grid from '@mui/joy/Grid';
import Typography from '@mui/joy/Typography';
import {ReadingDifficultyBadge} from "../../components/ReadingDifficultyBadge";
import {APP_NAME, imageURL, server, transcriptURL} from "../../globals";
import {UserContext} from "../../contexts";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {TextSegmentDisplay} from "../../components/TextSegmentDisplay";
import {TopicBreadcrumbs} from "../../components/TopicBreadcrumbs";
import Card from "@mui/joy/Card";
import {SourceDisplay} from "../../components/Sources/SourceDisplay";
import {AudioPlayer} from "../../components/AudioPlayer";
import {addToRead, addToReading, removeFromRead} from "../../settings";
import {Button} from "@mui/joy";
import BeenhereIcon from '@mui/icons-material/Beenhere';

export const TextPage = () => {
    const params = useParams();

    const [text, setText] = useState<any>(null);
    const [segmentNumber, setSegmentNumber] = useState<number>(0);
    const [segment, setSegment] = useState<any>(null);
    const [transcript, setTranscript] = useState<any>(null);

    const user = useContext(UserContext);

    const languageCode = params.languageCode
    const topicPath = params["*"] as string
    const textPath: string = `${languageCode}/${topicPath}`;

    // Set Page title
    useEffect(() => {
        if (text) {
            document.title = text.topic
        } else {
            document.title = APP_NAME
        }
    }, [text])

    // Load Text File
    useEffect(() => {
        const url = `${server}/texts/${textPath}/text.json`
        fetch(url)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setText(res)
            })
    }, [textPath]);

    // Load Segment File
    useEffect(() => {
        if (!text) { return; }
        const segment = text.segments[segmentNumber]
        const url = `${server}/texts/${textPath}/${segment.id}.json`

        fetch(url)
            .then(res => res.json())
            .then(res => {
                if (!res.transcript) {
                    setSegment(res)
                    return
                }

                fetch(transcriptURL(segment.id))
                    .then(res => res.json())
                    .then(res => {
                        setTranscript(res)
                    })
                    .catch((reason: any) => {console.error("No transcript for " + res.audio)})
                    .finally(() => setSegment(res))
            })
    }, [text, segmentNumber])

    // Automatically set loaded segments as "Currently reading"
    useEffect(() => {
        if (!segment || !user) {
            return
        }

        addToReading(user, segment)
    }, [user, segment])

    const audioPlayer = () => {
        if (!segment || !segment.audio) {
            return <React.Fragment/>
        }

        return <AudioPlayer audioID={segment.id} transcript={transcript}/>
    }


    return <Page>
        <Card variant="soft">
            <div onContextMenu={event => {event.preventDefault(); return false;}}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid xs={12} md={6}>
                        <img style={{width: "100%", display: "block"}}
                             alt=""
                            src={
                                text
                                    ? imageURL(text.segments[segmentNumber].active_image)
                                    : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                              }
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div>
                            <TopicBreadcrumbs topic={topicPath.split("/").slice(0, topicPath.split("/").length - 1).join("/")}/>
                        </div>
                        <VerticalPlaceholder height=".5em"/>
                        <Skeleton loading={!text}>
                            <Typography level="h3">
                                {text ? (text.title_translation ? <SentenceDisplay index={-1} sentence={text.title_translation[0]}/> : text.title) : "This title is currently being loaded..."}
                            </Typography>
                            <p>{text ? <ReadingDifficultyBadge score={text.difficulty_score}/> : "Loading..."}</p>
                        </Skeleton>
                    </Grid>

                    <Grid xs={12}>
                        {audioPlayer()}
                        <TextSegmentDisplay segment={segment}/>
                    </Grid>

                    <Grid xs={12}>
                        {
                            user && segment && user.value.settings.has_read.includes(segment.id)
                                ? <Button color="neutral" startDecorator={<BeenhereIcon/>} onClick={() => removeFromRead(user, segment)
                                    .then(() => user.update({...user.value}))}>
                                    You marked this text as Done
                                </Button>
                                : <Button color="success" startDecorator={<BeenhereIcon/>} onClick={() => addToRead(user, segment)
                                    .then(() => user.update({...user.value}))}>
                                    Mark text as Done
                                </Button>
                        }
                    </Grid>

                    <Grid xs={12}>
                        <SourceDisplay text={text}/>
                    </Grid>
                </Grid>
            </div>
        </Card>
    </Page>
}